<template>
  <LiefengContent>
    <template #title>批次管理</template>
    <template #toolsbarRight>
      <Form :label-colon="true" :inline="true" :label-width="80">
        <FormItem label="标题">
          <Input type="text" v-model="search.title" style="width:200px"></Input>
        </FormItem>
        <FormItem label="业务ID">
          <Input type="text" v-model="search.businessId" style="width:250px"></Input>
        </FormItem>
        <FormItem label="推送状态"   >
          <Select transfer v-model="search.pushStatus" style="width:200px">
            <Option   value="全部">全部</Option >
            <Option   value="1">未推送</Option >
            <Option   value="2">已推送</Option >
          </Select>
        </FormItem>
        <Button style="margin-right:10px" type="primary" icon="ios-search" @click="searchBtn" >查询</Button>
        <Button style="margin-right:10px" type="success" icon="ios-refresh" @click="resetBtn" >重置</Button>
      </Form>
    </template>
    <template #contentArea>
      <LiefengTable
          :talbeColumns="talbeColumns"
          :tableData="tableData"
          :loading="loading"
          :fixTable="true"
          :curPage="page"
          :total="total"
          :pagesizeOpts="[20, 30, 50, 100]"
          :page-size="pageSize"
          @hadlePageSize="hadlePageSize"
      ></LiefengTable>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
export default {
  components: {
    LiefengContent,
    LiefengTable,
  },
  data() {
    return {
      search:{
        title:'',
        businessId:'',
        pushStatus:'全部'
      },

      tableData: [],
      talbeColumns: [
        { align: "center",minWidth: 200, title: "标题", key: "title" },
        { align: "center",minWidth: 50, title: "消息数", key: "messageSize" },
        { align: "center",minWidth: 50, title: "数据包数量", key: "packageNum" },
        { align: "center",minWidth: 100, title: "推送进度", key: "packageNum" ,

          render: (h, params) => {
            return h(
                "Progress",
                {
                  props: {
                    type: "Progress",
                    size: "small",
                    percent: parseFloat(
                        (params.row.accounted)*100
                    ),
                  },
                },
                (params.row.accounted)*100 + "%"
            );
          },},
        { align: "center",minWidth: 50, title: "推送状态", key: "pushStatus",
          render: (h, params) => {
            let pushStatus = params.row.pushStatus
            var pushDetail = "";
            if (pushStatus == '0') {
              pushDetail = '进行中'
            }
            if (pushStatus == '1') {
              pushDetail = '未推送'
            }
            if (pushStatus == '2') {
              pushDetail = '已完成'
            }
            return h('div', [
              h('span', {},
                  pushDetail
              )
            ]);
          }},
        { align: "center",minWidth: 50, title: "批次状态", key: "batchStatus",
          render: (h, params) => {
            let batchStatus = params.row.batchStatus
            var batchDetail = "";
            if (batchStatus == 1) {
              batchDetail = '未推送';
            }
            if(batchStatus == '2'){
              batchDetail = '推送中';
            }
            if (batchStatus == '3') {
              batchDetail = '推送完成';
            }
            if (batchStatus == '4') {
              batchDetail = '取消推送';
            }
            if (batchStatus == '5') {
              batchDetail = '推送异常';
            }
            return h('div', [
              h('span', {},
                  batchDetail
              )
            ]);
          }},
        { align: "center",minWidth: 50, title: "归档状态", key: "hisStatus" , render: (h, params) => {
            let hisStatus = params.row.hisStatus
            return h('div', [
              h('span', {},
                  hisStatus === '1' ? '未归档' : '已归档'
              )
            ])
          }},
        { align: "center",minWidth: 100, title: "推送模板", key: "templateName" },
        {
          align: "center",
          title: "创建时间",
          key: "gmtCreate",
          minWidth: 200,
          render: (h, params) => {
            return h("div", [h("span", {}, params.row.gmtCreate ? this.$core.formatDate(new Date(params.row.gmtCreate), "yyyy-MM-dd hh:mm:ss") : "")])
          },
        },
        {
          fixed: "right",
          title: "操作",
          width: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                  "Dropdown",
                  {
                    props: {
                      transfer: true,
                    },
                  },
                  [
                    h(
                        "Button",
                        {
                          props: {
                            type: "info",
                            size: "small",
                            icon: "ios-arrow-down",
                          },
                        },
                        "操作"
                    ),
                    h(
                        "Button",
                        {
                          props: {
                            type: "error",
                            size: "small",
                          },
                          style: {
                            marginLeft: "14px",
                            display:params.row.status == 3 ? 'none' : 'inline-block'
                          },
                          on: {
                            click: () => {
                              this.openDetail(params.row)
                            },
                          },
                        },
                        "查看明细"
                    ), h(
                      "Button",
                      {
                        props: {
                          type: "error",
                          size: "small",
                        },
                        style: {
                          marginLeft: "14px",
                          display:params.row.pushStatus == 1 ? 'none' : 'inline-block'
                        },
                        on: {
                          click: () => {
                            this.cancelBatch(params.row)
                          },
                        },
                      },
                      "取消推送"
                  )
                  ]
              ),
            ]);
          },
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
    }
  },

  methods: {
    //分页
    hadlePageSize(val){
      this.page = val.page
      this.pageSize = val.pageSize
      this.getList()
    },
    tableSelect(val){
      console.log(val)
    },
    searchBtn(){
      this.page = 1
      this.pageSize = 20
      this.getList()
    },
    resetBtn(){
      this.page = 1
      this.pageSize = 20
      this.search.userName = ''
      this.search.mobile = ''
      this.search.status = '全部'
      this.getList()
    },
    openDetail(row) {
      parent.layer.open({
        type: 2,
        title: '查看明细',
        content: `/#/messagebatchdetail?batchId=` + row.id,
        area: ["100%", "100%"],
        end: () => {
          this.getList()
        },
      });
    },
    cancelBatch(row) {
      this.$post("/verifyapi/api/message/batch/cancelBatchId", {
        batchId:row.id
      })
          .then((res) => {
            if (res.code == 200) {
              this.$Message.success({
                background: true,
                content: "取消成功",
              });
            } else {
              this.$Message.error({
                background: true,
                content: "取消失败",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getList(){
      this.loading = true
      this.$get('/verifyapi/api/message/batch/selectByPage',{
        title:this.search.title,
        pushStatus:this.search.pushStatus == '全部'?'':this.search.pushStatus,
        businessId:this.search.businessId,
        page:this.page,
        pageSize:this.pageSize
      }).then( res => {
        this.loading = false
        if(res.code == 200 && res.dataList){
          this.tableData = res.dataList
          this.total = res.maxCount
          this.pageSize = res.currentPage
        }else{
          this.$MEssage.error({
            content:'获取数据失败',
            background:true
          })
        }
      })
    },
  },
  created() {
    this.getList()
  },
  components: {
    LiefengContent,
    LiefengTable,
  },
}
</script>

<style scoped lang='less'>
</style>